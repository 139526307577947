import { t } from "i18next";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { RouteProps } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/ArrowDownwardOutlined";

import config from "@APP/config";
import { API } from "@APP/services";
import { ICONS } from "@APP/assets";
import { useAlert } from "@APP/hooks";
import { history } from "@APP/navigation";
import { formatCurrency } from "@APP/utils";
import { clearRtpState } from "@APP/redux/actions";
import { useAppDispatch, useAppSelector } from "@APP/redux";
import { findErrorCodeByMessage } from "@APP/types/errorCodes";
import { PageLayout, Message, MoneyhubLegalInfo } from "@APP/components";
import { ATTACHEMENTS, PaymentStatus, PAYMENT_PROVIDER } from "@APP/types";
import { getModalMessageDownloadInvoiceFailure } from "@APP/components/RTPSummary/getModalContent";

import { getModalCommonButton } from "../DeclineRTPScreen/getModalContent";

const ERROR_MESSAGES = {
  CHECK_BANK:
    "We have been unable to get an update on your payment from your bank. Please check your bank to see if the payment has been made.",
  ACCESS_DENIED:
    "We understand that sometimes you wish to change your mind about making payment. If you have any questions or are concerned about the payment request please contact the business that sent it directly",
  MAVERICK_ERROR:
    "We are unable to update the status of your payment. Please try again to update your payment status.",
};

const RTPCompletePaymentScreen: React.FC<RouteProps> = ({ location }) => {
  const dispatch = useAppDispatch();

  const { alert } = useAlert();

  const rtpState = useAppSelector((state) => state.rtp);

  const [loading, setLoading] = useState(true);
  const [rtpNotAvailable, setRtpNotAvailable] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorMessageTitle, setErrorMessageTitle] = useState<string>(
    "Oops, something seems to have gone wrong",
  );
  const [loaderMessage, setLoaderMessage] = useState<string>("");
  const [confirmationInfo, setConfirmationInfo] = useState<{
    sender: string;
    amount: { amount: string; currency: string };
    bank?: string;
    reference: string;
    invoiceUrl?: string;
  }>();

  const queryParams = queryString.parse(location?.hash ?? "");
  const {
    code,
    state,
    error,
    error_description,
    status: maverickPaymentStatus,
    id: maverickPaymentId,
  } = queryParams;

  const processMoneyhubPayment = async () => {
    try {
      //
      // STEP 1: get payment state with all the necessary data for confirmation.
      //
      const paymentState = await API.getPaymentState(state as string);
      const { consentId, meta } = paymentState;
      const custodianId = paymentState.context.custodianId;
      const paymentStatus = paymentState.context.status;
      if (paymentStatus === PaymentStatus.Authorized) {
        setRtpNotAvailable(true);
      } else {
        setLoaderMessage("We are just confirming the payment with your bank");
        const { BankId, redirectUrl, invoiceUrl, initialInvoiceType, erpId, features } =
          meta.reduce((prev, curr) => {
            if (
              curr.key &&
              [
                "BankId",
                "redirectUrl",
                "invoiceUrl",
                "initialInvoiceType",
                "erpId",
                "features",
              ].includes(curr.key)
            ) {
              return { ...prev, [curr.key]: curr.value };
            }
            return prev;
          }, {} as { [key: string]: string });

        // Download button should be displayed in case of external invoice or internal native invoice:
        const shouldDisplayDownloadLink =
          invoiceUrl && (erpId !== "internal" || features.includes("Native"));

        if (rtpState.rtp?.standingOrder) {
          //
          // STEP 2: Confirm payment.
          //
          const paymentConfirmResponse = await API.confirmStandingOrderPayment(
            custodianId,
            consentId,
            code as string,
            redirectUrl,
            { additionalData: { initialInvoiceType } },
          );

          if (!paymentConfirmResponse || paymentConfirmResponse.status === PaymentStatus.Rejected) {
            throw new Error("'processPaymentConfirmation()' invalid response");
          }

          // STEP 3: Update RTP with standing order.
          const rtpUpdateResponse = await API.updateStandingOrderPayment(
            rtpState.rtpData!.paymentRequestId!,
            paymentConfirmResponse.paymentId,
            paymentConfirmResponse.payment.firstPaymentDateTime,
          );
          setConfirmationInfo({
            amount: paymentConfirmResponse.payment.firstPaymentAmount,
            bank: custodianId,
            sender:
              rtpUpdateResponse.supplier.businessContact?.name ?? rtpUpdateResponse.supplier.name,
            reference: paymentConfirmResponse.paymentId,
            invoiceUrl: shouldDisplayDownloadLink ? invoiceUrl : "",
          });
        } else {
          //
          // STEP 2: Confirm payment.
          //
          await authorizePayment(
            BankId,
            consentId,
            code as string,
            redirectUrl,
            initialInvoiceType,
            shouldDisplayDownloadLink,
            invoiceUrl,
          );
        }
      }
    } catch (error: any) {
      Sentry.captureMessage(
        `Payment confirmation error,", ${JSON.stringify(error?.response?.data ?? error)}`,
      );
      setLoaderMessage("");
      setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
    }

    setLoading(false);
  };

  const checkPaymentStatus = async (
    BankId: string,
    consentId: string,
    code: string,
    redirectUrl: string,
    initialInvoiceType: any,
    shouldDisplayDownloadLink: any,
    invoiceUrl: string,
    retryCount: number = 3,
    retryInterval: number = 5000,
  ) => {
    for (let attempt = 1; attempt <= retryCount; attempt++) {
      try {
        const paymentConfirmResponse = await API.authorizePayment(
          BankId,
          consentId,
          code as string,
          redirectUrl,
          { additionalData: { initialInvoiceType } },
        );

        if (paymentConfirmResponse && paymentConfirmResponse.status !== PaymentStatus.Rejected) {
          // Payment was successful; set the confirmation info
          setConfirmationInfo({
            amount: paymentConfirmResponse.payment.amount,
            bank: BankId,
            sender: rtpState.senderData?.businessContact?.name ?? rtpState.senderData!.name,
            reference: paymentConfirmResponse.paymentId,
            invoiceUrl: shouldDisplayDownloadLink ? invoiceUrl : "",
          });
          return;
        } else {
          throw new Error("'processPaymentConfirmation()' invalid response");
        }
      } catch (error: any) {
        if (attempt === retryCount) {
          console.log(`Payment confirmation failed after ${retryCount} attempts.`);
          Sentry.captureMessage(
            `Payment confirmation  failed after ${retryCount} attempts,", ${JSON.stringify(
              error?.response?.data ?? error,
            )}`,
          );
          throw new Error(error);
        }

        // If the error is a 503, wait for the interval before retrying
        if (error.message.includes("503")) {
          console.log(`Payment confirmation failed after ${attempt} attempts.`);
          Sentry.captureMessage(
            `Payment confirmation  failed after ${attempt} attempts,", ${JSON.stringify(
              error?.response?.data ?? error,
            )}`,
          );
          await new Promise((resolve) => setTimeout(resolve, retryInterval));
        } else {
          // If it's a different error, throw it immediately
          throw error;
        }
      }
    }
  };

  const authorizePayment = async (
    BankId: string,
    consentId: string,
    code: string,
    redirectUrl: string,
    initialInvoiceType: any,
    shouldDisplayDownloadLink: any,
    invoiceUrl: string,
  ) => {
    try {
      await checkPaymentStatus(
        BankId,
        consentId,
        code,
        redirectUrl,
        initialInvoiceType,
        shouldDisplayDownloadLink,
        invoiceUrl,
      );
    } catch (error) {
      throw error;
    }
  };

  const processMaverickPayment = async () => {
    try {
      setErrorMessage(undefined);

      if (!maverickPaymentId || !maverickPaymentStatus) {
        setLoading(false);
        setErrorMessage(ERROR_MESSAGES.ACCESS_DENIED);

        return;
      }

      await API.updateMaverickPaymentStatus({
        rtpId: rtpState.rtp?.id!,
        customerId: rtpState.rtpData?.payable.supplierContact.email!,
        paymentDetails: {
          paymentId: Number(maverickPaymentId),
          status: maverickPaymentStatus as string,
        },
        amount: rtpState.rtpData?.paymentDetails.amount!,
        entityDetails: rtpState.rtpData?.payable.entityDetails!,
      });

      if (maverickPaymentStatus !== "Success") {
        return setErrorMessage(ERROR_MESSAGES.CHECK_BANK);
      }

      setConfirmationInfo({
        amount: rtpState.rtp!.amount,
        sender: rtpState.rtp!.supplier.businessContact?.name ?? rtpState.rtp!.supplier.name,
        reference: rtpState.rtp!.receivable.reference,
      });
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.MAVERICK_ERROR);
    }

    setLoading(false);
  };

  const processCardPayment = async () => {
    const invoiceLink = rtpState.rtpData?.payable?.attachments.find(
      (item) => item.name === ATTACHEMENTS.RECEIVABLE_PDF && item.contentType === "application/pdf",
    )?.uri;

    try {
      setConfirmationInfo({
        amount: rtpState.rtp!.amount,
        sender: rtpState.rtp!.supplier.businessContact?.name ?? rtpState.rtp!.supplier.name,
        reference: rtpState.rtp!.receivable.reference,
        invoiceUrl: invoiceLink,
      });
      setLoading(false);
    } catch (e) {
      console.log("Whoops", e);
    }
  };

  const onTryAgainMaverickUpdate = () => {
    setLoading(true);
    processMaverickPayment();
  };

  const handleFinish = () => {
    history.push("/");
    dispatch(clearRtpState());
  };

  useEffect(() => {
    (async () => {
      const { cardPaymentFlow } = (location?.state ?? {}) as {
        cardPaymentFlow: boolean | undefined;
      };

      if ((error || !code) && !cardPaymentFlow) {
        Sentry.captureMessage(`Payment confirmation error (query param received): "${error}" `);
        const result = findErrorCodeByMessage(error as string);
        if (result.errorFound && result.errorMessage?.text) {
          setErrorMessageTitle(result.errorMessage?.title);
          setErrorMessage(result.errorMessage?.text + result.errorCode);
        } else {
          setErrorMessage(
            error === "access_denied" || error === "login_required"
              ? ERROR_MESSAGES.ACCESS_DENIED
              : ERROR_MESSAGES.CHECK_BANK,
          );
        }
        try {
          if (error) {
            const paymentState = await API.getPaymentState(state as string);
            const { consentId } = paymentState;
            const custodianId = paymentState?.context?.custodianId;
            await sendPisError(
              consentId,
              custodianId,
              error as string,
              error_description as string,
            );
          }
        } catch (e) {}
        setLoading(false);
        return;
      }

      if (config?.FEATURE?.SQUARE && cardPaymentFlow === true) {
        processCardPayment();
        return;
      }

      if (config.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MAVERICK) {
        processMaverickPayment();
        return;
      }

      processMoneyhubPayment();
    })();
  }, []);

  // Sent PIS error  & description (if any) to BE
  const sendPisError = async (
    consentId: string,
    bankId: string,
    error: string | null,
    error_description: string,
  ) => {
    try {
      if (consentId && bankId) await API.sendPisError(bankId, consentId, error, error_description);
    } catch (e) {}
  };

  const handleResetClick = () => {
    dispatch(clearRtpState());
    history.push("/");
  };

  const renderMainContent = () => {
    if (rtpNotAvailable) {
      return (
        <Message
          style={{ margin: "auto" }}
          type="info"
          title="Oops, something seems to have gone wrong"
          description={t("Screens.Landing.error1")}
          buttons={[{ variant: "contained", color: "primary", onClick: handleResetClick }]}
        />
      );
    }

    if (errorMessage) {
      return (
        <Box display="flex" alignItems="center" pt="12vh" id="rtpCompletePaymentErrorBox">
          <Message
            type="error"
            title={errorMessageTitle}
            description={errorMessage}
            buttons={[
              errorMessage === ERROR_MESSAGES.MAVERICK_ERROR
                ? {
                    text: "Try again",
                    variant: "contained",
                    color: "secondary",
                    onClick: onTryAgainMaverickUpdate,
                  }
                : {
                    text: "Okay",
                    variant: "contained",
                    color: "secondary",
                    onClick: handleFinish,
                  },
            ]}
          />
        </Box>
      );
    }

    async function downloadInvoice() {
      if (confirmationInfo?.invoiceUrl) {
        try {
          // the "regenerate" flag signals the backend to generate a new PDF document for the paid invoice
          // Fetch the invoice
          const response = await fetch(confirmationInfo.invoiceUrl + "?regenerate=true", {
            method: "GET",
          });

          // Get the Content-Type header
          const contentType = response.headers.get("Content-Type");

          if (contentType === "application/pdf") {
            // Create a Blob and open it in a new tab
            const blob = await response.blob();
            const pdfUrl = URL.createObjectURL(blob);
            const newTab = window.open(pdfUrl, "_blank");
            // Add "noopener" and "noreferrer" for security
            if (newTab) {
              newTab.opener = null; // Equivalent to "noopener"
            }
          } else if (contentType === "application/json") {
            // Parse the JSON error response
            const errorData = await response.json();
            alert("", getModalMessageDownloadInvoiceFailure(errorData), getModalCommonButton());
          } else {
            throw new Error("Unexpected content type");
          }
        } catch (error) {
          alert("", getModalMessageDownloadInvoiceFailure(), getModalCommonButton());
        }
      }
    }
    return (
      <Box display="flex" flexDirection="column" alignItems="center" id="rtpCompletePaymentBox">
        {confirmationInfo && (
          <>
            <img
              src={ICONS.CONFIRM_ICON}
              style={{ width: 60 }}
              alt="Success"
              id="rtpCompletePaymentSuccessImage"
            />
            <Box mt={3}>
              <Typography component="p" variant="h6" id="rtpCompletePaymentSuccessTypo">
                Payment Successful
              </Typography>
            </Box>
            <Box mt="2.5vh" mb="3vh" textAlign="center">
              <Typography component="p" variant="subtitle1" id="rtpCompletePaymentTo">
                To: {confirmationInfo.sender}
              </Typography>
              <Box my={1}>
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="h5"
                  id="rtpCompletePaymentAmount">
                  Amount: {formatCurrency(confirmationInfo.amount)}
                </Typography>
              </Box>
              {confirmationInfo.bank ? (
                <Typography
                  component="p"
                  variant="subtitle1"
                  id="rtpCompletePaymentConfirmationBank"
                  style={{ textTransform: "capitalize" }}>
                  {confirmationInfo.bank}
                </Typography>
              ) : null}
              {confirmationInfo.invoiceUrl && (
                <Box mt={2}>
                  <Button
                    size="small"
                    id="rtpCompletePaymentDownloadInvoiceButton"
                    onClick={downloadInvoice}
                    startIcon={<DownloadIcon />}>
                    Download Invoice
                  </Button>
                </Box>
              )}
              {confirmationInfo.reference && (
                <Box mt={2} id="rtpCompletePaymentRefBox">
                  <Typography variant="body2" align="center" id="rtpCompletePaymentRefNumber">
                    Payment Reference:&nbsp;
                    <code>{confirmationInfo.reference}</code>
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
        <Box width={"100%"} mb={3} id="rtpCompletePaymentFinishBox">
          <Button
            size="large"
            fullWidth
            variant="contained"
            id="rtpCompletePaymentFinishButton"
            color="primary"
            onClick={handleFinish}>
            Finish
          </Button>
        </Box>
        <MoneyhubLegalInfo />
      </Box>
    );
  };

  return (
    <PageLayout loading={loading} loaderText={loaderMessage}>
      <Box display="flex" alignItems="center" flexDirection="column" maxWidth={560} mx="auto">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default RTPCompletePaymentScreen;
