import * as CookieConsent from "vanilla-cookieconsent";

const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000;
const CC_COOKIE_NAME = "cc_cookie";

// Helper function to get a cookie value by name
const getCookieValue = (name: string): string | null => {
  const cookieMatch = document.cookie.split("; ").find((row) => row.startsWith(`${name}=`));
  return cookieMatch ? decodeURIComponent(cookieMatch.split("=")[1]) : null;
};

// Helper function to remove a cookie by name
const removeCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
};

// Check if the cookie should be removed and consent modal reinitialized
const validateConsentCookie = (): void => {
  const ccCookie = getCookieValue(CC_COOKIE_NAME);
  if (!ccCookie) return; // No cookie present, no action needed

  try {
    const parsed = JSON.parse(ccCookie);
    const { categories, lastConsentTimestamp } = parsed;

    // Check if "analytics" is not in categories and consent is older than 7 days
    const consentExpired = Date.now() - new Date(lastConsentTimestamp).getTime() > SEVEN_DAYS_IN_MS;
    const analyticsNotIncluded = !categories?.includes("analytics");

    if (analyticsNotIncluded && consentExpired) {
      // Remove the cookie and reinitialize consent modal
      removeCookie(CC_COOKIE_NAME);
    }
  } catch (error) {
    // If cookie parsing fails, remove the cookie as a fallback
    removeCookie(CC_COOKIE_NAME);
  }
};

const waitForClarity = async (): Promise<void> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window?.clarity) {
        clearInterval(interval); // Stop checking once clarity is initialized
        resolve();
      }
    }, 100); // Check every 100ms
  });
};

const giveConscentToMSClarity = async (cookie: any) => {
  // Wait for Microsoft Clarity to initialize
  await waitForClarity();

  if (cookie?.categories.includes("analytics")) {
    // Provide consent to Microsoft Clarity
    if (window?.clarity) {
      window.clarity("consent");
    }
  } else {
    if (window?.clarity) {
      window.clarity("consent", false);
    }
  }
};

export const initializeCookieConsent = () => {
  // Validate existing consent cookie and remove it if needed
  validateConsentCookie();

  // Run the cookie consent modal
  CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: "bar inline",
        position: "bottom",
        flipButtons: false,
        equalWeightButtons: false,
      },
      preferencesModal: {
        equalWeightButtons: false,
        flipButtons: true,
      },
    },
    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      analytics: {
        enabled: true, // this category is enabled by default
      },
    },
    onFirstConsent: ({ cookie }) => {
      giveConscentToMSClarity(cookie);
    },
    onConsent: ({ cookie }) => {
      giveConscentToMSClarity(cookie);
    },
    onChange: ({ cookie }) => {
      giveConscentToMSClarity(cookie);
    },
    language: {
      default: "en",
      translations: {
        en: {
          consentModal: {
            title: "We value your privacy",
            description:
              "Our website uses cookies to understand how visitors interact with our content. We use 3rd party apps to collect anonymized data for analytics purposes. By clicking 'Accept All', you consent to the use of all cookies. You can manage your preferences or opt out at any time.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Customize",
          },
          preferencesModal: {
            title: "Customize Consent Preferences",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Accept current selection",
            closeIconLabel: "Close modal",
            sections: [
              {
                description:
                  "We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below. The cookies that are categorized as 'Necessary' are stored on your browser as they are essential for enabling the basic functionalities of the site. You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience. We use cookies and similar technologies to provide you with a better user experience. This includes:",
              },
              {
                title: "Essential Cookies",
                description: "Necessary for the website to function correctly.",
                linkedCategory: "necessary",
              },
              {
                title: "Analytics Cookies",
                description:
                  "We use Microsoft Clarity to gather insights into user behavior on our site. This data is anonymized and helps us improve our content and design.",
                linkedCategory: "analytics",
              },
              {
                title: "Managing Your Preferences",
                description:
                  "You can enable/ disable cookies through your browser settings. Disabling cookies may affect the functionality of certain features.",
              },
            ],
          },
        },
      },
    },
  });
};
